@import "../main/abstracts/index";

.event-list{
    &__item{
        .card{
            &-body{
                color: $color__white;
                background-color: $color__primary;
                &:hover,
                &.focus-within{
                    background-color: $color__link--hover;
                }
            }
            // &-date{
            //     &:before{
            //         content:"";
            //         width: 100%;
            //         height: 1px;
            //         background-color: $color__white;
            //         position: absolute;
            //         left: 0;
            //         opacity: 0.3;
            //         top: 7rem;
            //     }
            // }
            &-title{
                a{
                    color: $color__white ;
                }
            }
            .pimcore_editable_input,
            a.stretched-link{
                color: $color__white!important;
            }
        }
        img{
            filter: $filter__white;
        }
    }
}
